import React from 'react';
import { Form, Input, Button,Select,Spin,Result,DatePicker,message,Card,Switch,InputNumber,Image } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import CurrencyFormat from 'react-currency-format';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;


var sacco= ''
var username=''
var token= ''

class LoanAppovalForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    approved_amount:0,
    amount_appliedfor:0,

    loan_pass:false,
    approval_report:'',
    loanapplication:{},
    loanassessment:{},
    datasubmittedsuccessfully:false,
    datarequested:true,
    collaterallist:[],
    reasonforrejection:'',
    loantypeobject:{},
    approved_period:0,
    graceperiod:0

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    const loanapplicationID=this.props.match.params.loanapplicationID;

    axios.get(`${serverconfig.backendserverurl}/api/loanapplications/${loanapplicationID}`)
    .then(res => {  
        this.setState({loanapplication: res.data})
        this.setState({amount_appliedfor:res.data.loan_amount})

        axios.get(`${serverconfig.backendserverurl}/api/loantypes/${res.data.loan_type}`)
        .then(res => {  
            this.setState({loantypeobject: res.data})
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/loanassessments/?loan_assessed=${loanapplicationID}`)
    .then(res => {  
        this.setState({loanassessment: res.data[0]})
    })

    axios.get(`${serverconfig.backendserverurl}/api/loanassessmentcollaterals/?loan_assessed=${loanapplicationID}`)
    .then(res => {  
        this.setState({collaterallist: res.data})
        this.setState({datarequested:false})
    })

  }

  onBlur=()=> {
    console.log('blur');
  }
  
  onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    const loanapplicationID=this.props.match.params.loanapplicationID;

    if(Number(this.state.approved_amount)>Number(this.state.loanassessment.loan_amount)){
      message.error("Approved amount cant be greater than amount applied for")
    }else if (Number(this.state.approved_amount)>Number(this.state.loanassessment.application_loan_limit)){
      message.error("Approved amount cant be greater than the application loan limit")
    }else if (Number(this.state.approved_amount)<=0 && this.state.loan_pass===true){
      message.error("Approved amount cant be zero or less")
    } else if (this.isloan_approved()===false && this.state.loan_pass===true){
      message.error("Loan cant be approved finally.More approvals needed")
    }
    else if (Number(this.state.approved_period)>Number(this.state.loantypeobject.maximum_period)){
      message.error("Period cant be greater than the maximum set period")
    }
    
    else
    {
      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('date', this.state.date);
      form_data.append('loan_id', loanapplicationID);
      form_data.append('approved_amount', this.state.approved_amount);
      form_data.append('amount_appliedfor', this.state.loanassessment.loan_amount);
      form_data.append('approval_report', this.state.approval_report);
      form_data.append('loan_passed', this.state.loan_pass);
      form_data.append('user',username );
      form_data.append('reasonforrejection', this.state.reasonforrejection);
      form_data.append('sacco', sacco);
      form_data.append('approved_period', this.state.approved_period);
      form_data.append('graceperiod', this.state.graceperiod);
      axios.post(serverconfig.backendserverurl+'/api/loanapprovals/', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>  {
      var obj=res.data
      console.log(Object.keys(obj).length)
      if(Object.keys(obj).length>0){
  
        let form_data = new FormData();
        form_data.append('looanid', loanapplicationID);
        form_data.append('reasonforrejection', this.state.reasonforrejection);
        form_data.append('approved_period', this.state.approved_period);
        form_data.append('approved_amount', this.state.approved_amount);
        form_data.append('date', this.state.date);
        form_data.append('graceperiod', this.state.graceperiod);

        if(this.state.loan_pass===true){
          form_data.append('loanpassed', 'true');
        }else{
          form_data.append('loanpassed', 'false');
        }
  
        axios.post(serverconfig.backendserverurl+'/customqueries/updateloanappovalstatus', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
            message.info(res.data.message)
            this.setState({datarequested:false})
            this.setState({datasubmittedsuccessfully:true})
        })
        .catch(error => console.log(error))
  
      }else{
        message.info("Some thing went wrong")
      }
  
    })
    .catch(error => console.log(error))
    }

  }

 
  handleapproved_amountChange= (e) => this.setState({ approved_amount: e.target.value});
  handleloan_passChange= (e) => this.setState({ loan_pass: e});
  handleapproval_reportChange= (e) => this.setState({ approval_report: e.target.value});

  //check approvals
  isloan_approved=()=>{
    var current_approvals=0

    if (this.state.loanapplication.loanapprovals!=undefined){
      current_approvals=JSON.parse(this.state.loanapplication.loanapprovals).length
    }
  
    if (current_approvals<Number(this.state.loantypeobject.no_of_approvers)){
      return false
    }else{
      return true
    }


  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
          <Row >
            <Col xs="12" sm="6" lg="6">
            <Card>
            <Result
            status="success"
            title="Successfully Done loan approval "
            subTitle="If the loan is passed it will be forwarded for issuance or disburshment"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />

            </Card>
            </Col>
            </Row>
        );

      }else{
        return (
          <div>
              <Row >
                    <Col xs="12" sm="6" lg="6">
                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN APPROVAL FORM</h4>
                     
                     {
                       this.state.loanapplication.approval_yet?
                       null:
                       <Form
                       onFinish={(event) => this.handleFormSubmit(event)} 
                     >

                          
                    <FormItem label="Date"
                      name='date'
                      rules={[
                        {
                          required: true,
                          message: 'Please Select date',
                        },
                      ]}
                    >
                        <DatePicker value={moment()} onChange={(date, dateString) => this.setState({ date: dateString})} format={dateFormat} />
                    </FormItem>
         

                    <FormItem label="Amount approved">
                         <Input name="approved_amount" type='number'  placeholder="Approval Amount." value={this.state.approved_amount} onChange={this.handleapproved_amountChange} />
                       </FormItem>

                       <FormItem label={this.state.loanapplication.loan_primary_schedule==="monthly"?"No of months":this.state.loanapplication.loan_primary_schedule==="weekly"?"No of weeks":"No of days"}  >       
                         <Input name="approved_period" type='number'  placeholder="Approved period." value={this.state.approved_period} onChange={(val)=>{this.setState({approved_period:val.target.value})}} />
                       </FormItem>

                     <FormItem label={"Grace period (days)"}  >       
                         <Input name="graceperiod" type='number'  placeholder="Grace period." value={this.state.graceperiod} onChange={(val)=>{this.setState({graceperiod:val.target.value})}} />
                      </FormItem>

                     <FormItem label="Approval remarks"
                             name="approval_report"
                             rules={[
                               {
                                 required: true,
                                 message: 'Please enter remarks',
                               },
                             ]}            
                           >
                             <TextArea
                               value={this.state.approval_report}
                               onChange={this.handleapproval_reportChange}
                               placeholder="Approver remarks"
                               autoSize={{ minRows: 2, maxRows: 10 }}
                             />
                         
                       </FormItem>

                       <FormItem label="Pass Loan?" >
                           <Switch
                               checked={this.state.loan_pass}
                               onChange={val=>{this.handleloan_passChange(val)}}
                               checkedChildren="True"
                               unCheckedChildren="False"
                               />
                       </FormItem>

                      {
                        this.state.loan_pass===false?
                        <div>
                        <FormItem label="Reason For Rejection"
                        name="reasonforrejection"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter reason',
                          },
                        ]}            
                      >
                        <TextArea
                          value={this.state.reasonforrejection}
                          onChange={(val)=>{this.setState({reasonforrejection:val.target.value})}}
                          placeholder="Reason"
                          autoSize={{ minRows: 2, maxRows: 10 }}
                        />

                        </FormItem>

                        </div>

                        :
                        null

                      }


                       <FormItem>
                         <Button  type="primary" htmlType="submit">Submit</Button>
                       </FormItem>
                     </Form>
                     }
                     
                      </Card>
                      </Col>
  
  
                      <Col xs="12" sm="6" lg="6">

                      <Card>
                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>Application summary</h4>
                      <h6 style={{fontWeight:'bold'}}>Amount applied for; <CurrencyFormat value={this.state.loanassessment.loan_amount} displayType={'text'} thousandSeparator={true}/></h6>
                      <h6 style={{fontWeight:'bold'}}>Application loan limit; <CurrencyFormat value={this.state.loanassessment.application_loan_limit} displayType={'text'} thousandSeparator={true}/></h6>
                      <h6 style={{fontWeight:'bold'}}>Current number of approvals: {this.state.loanapplication.loanapprovals!=undefined?    JSON.parse(this.state.loanapplication.loanapprovals).length:null}</h6>
                      <h6 style={{fontWeight:'bold'}}>Minimum total approvals: {this.state.loantypeobject.no_of_approvers}</h6>

                      <br></br>


                      <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>Loan Assessment Details</h4>
                      <h6 style={{fontWeight:'bold'}}>Applicant Location: {this.state.loanassessment.applicant_location?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Applicant Own Premises: {this.state.loanassessment.own_premises?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Legal Status Of Business: {this.state.loanassessment.legal_status_of_business?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Duration In Business: {this.state.loanassessment.duration_in_business?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Monthly Net Profit Of Business: {this.state.loanassessment.monthly_net_profit?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Guarantors available? {this.state.loanassessment.guarantors?"True":"False"}</h6>
                      <h6 style={{fontWeight:'bold'}}>Conscents available? {this.state.loanassessment.consents?"True":"False"}</h6>
                     
                     {
                       this.state.loanassessment.loan_checklist===null?
                       null:
                       <h6 style={{fontWeight:'bold'}}>CheckList: <a href={this.state.loanassessment.loan_checklist}><Button>DownLoad Check List</Button></a></h6>

                     }

                     <h6 style={{fontWeight:'bold'}}>Assesser Remarks: {this.state.loanassessment.report_details}</h6>

                     <h4 style={{fontStyle:'oblique',fontWeight:'bolder',color:'green'}}>Loan Collaterals</h4>
                     
                     {
                      this.state.collaterallist.length>0?
                      <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Value</th>
                          <th>Image </th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.collaterallist.map(
                        (item)=>(
                          <tr>
                          <td>{item.collateral}</td>
                          <td>{item.collateral_value}</td>
                          <td>
                            <Image
                            width={50}
                            height={50}
                            src={item.collateral_image}    
                            />
                          </td>
                          </tr>
                        ))}
                    </tbody>
                    </reactstrp.Table>  
                    :
                    null
                  }


                  {
                    this.state.loanapplication.collateraltype==="reaperstitle"?
                    <div>
                      <h4>Attach Land Valuer: {this.state.loanassessment.sacco_landvaluer_name}</h4>
                    </div>
                    :
                    null
                  }


                     
                </Card>
                </Col>
              </Row>
  
          </div>
        );

      }

     
    }


    
  }

}


export default LoanAppovalForm;



