import React from 'react';
import { Form, Input, Button,Select,Switch,Spin,DatePicker,Result,message,Card,InputNumber,Table } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import { number } from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'DD/MM/YYYY';

var sacco= ''
var token= ''


class LoanCalculator extends React.Component {

  state = {
    datetoday:moment().format(dateFormat).toString(),
    loanamount:0,
    interestrate:0,
    interestratetype:'',
    loanperiod:0,
    datarequested:true,
    dateofissuance:moment(),
    dateofissuancestring:moment().format(dateFormat).toString(),
    proposedschedule:[],
    roundoffvalue:0,
    noofdaysinloan:0,
    showdailypay:false,
    scheduletype:'monthly',
    excludesaturdays:false,
    excludesundays:false,
    dailyschedule:[]

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }
    
    this.setState({datarequested:false})
  }


  //calculate principleTotal
  calculateScheduleTotal=()=>{
    var principleTotal=0
    this.state.proposedschedule.map(
      (item)=>{
        principleTotal+=Number(item.principle)
      })

    return principleTotal;
  }

    //calculate principleTotal
    calculateInterestTotal=()=>{
      var InterestTotal=0
      this.state.proposedschedule.map(
        (item)=>{
          InterestTotal+=Number(item.interest)
        })
  
      return InterestTotal;
    }


 




    //calculate principleTotal
    calculateTotalPay=()=>{
      var TotalPay=0
      this.state.proposedschedule.map(
        (item)=>{
          TotalPay+=Number(item.total)
        })
  
      return TotalPay;
    }

  //submit button pressed
  handleFormSubmit=(event) =>{
    //clear the schedule
    this.setState({proposedschedule:[]})
    this.setState({datarequested:true})

    if (this.state.interestratetype==="flat"){
      var totalprinciple=Number(this.state.loanamount)
      var principlepay=totalprinciple/Number(this.state.loanperiod)
      var interestpay=Number(totalprinciple)*(Number(this.state.interestrate)/100)
      var period=Number(this.state.loanperiod)

      console.log(this.countDecimals(principlepay))
      if(this.countDecimals(principlepay)>0){
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {

                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }

            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();

            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

             var scheduleitem={
                key:i,
                date:paydate,
                principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
                principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
                interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
                principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
                total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
              }

              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });



                //get the remaining principle balance
                totalprinciple-=Number(principlepay)

                }

                //total on schedule minus principle
                var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)

                
                //now go ahead and edit last row
                const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)

                let newArray = [...this.state.proposedschedule]
           
                var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)

                newArray[elementsIndex] = {...newArray[elementsIndex], 
                  principle:lastpayableprinciple,
                  principlebalance:0,
                  total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
                }
            
                this.setState({
                proposedschedule: newArray,
                });


                this.setState({datarequested:false}) 


      }else{
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {

                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }

            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();


            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

              var scheduleitem={
                key:i,
                date:paydate,
                principlebf:totalprinciple,
                principle:principlepay,
                interest:interestpay,
                principlebalance:totalprinciple-principlepay,
                total:Number(principlepay)+Number(interestpay)
              }

              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });

              //get the remaining principle balance
              totalprinciple-=Number(principlepay)

              }

              this.setState({datarequested:false}) 

        }

     

    }else{
      var totalprinciple=Number(this.state.loanamount)
      var principlepay=totalprinciple/Number(this.state.loanperiod)
      var period=Number(this.state.loanperiod)

      console.log(this.countDecimals(principlepay))
      if(this.countDecimals(principlepay)>0){
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {

                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }

            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();

            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

              //now set interest in the loop only
              var interestpay=Number(totalprinciple)*(Number(this.state.interestrate)/100)


              var scheduleitem={
                key:i,
                date:paydate,
                principlebf:Math.ceil(totalprinciple/this.state.roundoffvalue)*this.state.roundoffvalue,
                principle:Math.ceil(principlepay/this.state.roundoffvalue)*this.state.roundoffvalue,
                interest:Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
                principlebalance:Math.ceil((totalprinciple-principlepay)/this.state.roundoffvalue)*this.state.roundoffvalue,
                total:Math.ceil((Number(principlepay)+Number(interestpay))/this.state.roundoffvalue)*this.state.roundoffvalue
              }

              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });



                //get the remaining principle balance
                totalprinciple-=Number(principlepay)

                }

                //total on schedule minus principle
                var diffinprincipleprinciple=Number(this.calculateScheduleTotal())-Number(this.state.loanamount)

                
                //now go ahead and edit last row
                const elementsIndex = this.state.proposedschedule.findIndex(element => element.key ==period)

                let newArray = [...this.state.proposedschedule]
           
                var lastpayableprinciple=Number(this.state.proposedschedule[elementsIndex].principle)-Number(diffinprincipleprinciple)

                newArray[elementsIndex] = {...newArray[elementsIndex], 
                  principle:lastpayableprinciple,
                  principlebalance:0,
                  total:Number(lastpayableprinciple)+Math.ceil(interestpay/this.state.roundoffvalue)*this.state.roundoffvalue,
                }
            
                this.setState({
                proposedschedule: newArray,
                });


                this.setState({datarequested:false}) 



      }else{
            //split issuance date to create loop
            var dataSplit = this.state.dateofissuancestring.split('/');
            var dateConverted;
            if (dataSplit[2].split(" ").length > 1) {

                var hora = dataSplit[2].split(" ")[1].split(':');
                dataSplit[2] = dataSplit[2].split(" ")[0];
                dateConverted = new Date(dataSplit[2], dataSplit[1]-1, dataSplit[0], hora[0], hora[1]);

            } else {
                dateConverted = new Date(dataSplit[2], dataSplit[1] - 1, dataSplit[0]);
            }

            var year = dateConverted.getFullYear();
            var month = dateConverted.getMonth();
            var date = dateConverted.getDate();


            for (var i=1;i<=period;i++){
              //set date now 
              var paydate=new Date(year,month+i, date).toLocaleDateString('en-GB', {day : 'numeric',month : 'numeric',year : 'numeric'}).split(' ').join('/');

              var interestpay=Number(totalprinciple)*(Number(this.state.interestrate)/100)

              var scheduleitem={
                key:i,
                date:paydate,
                principlebf:totalprinciple,
                principle:principlepay,
                interest:interestpay,
                principlebalance:totalprinciple-principlepay,
                total:Number(principlepay)+Number(interestpay)
              }

              //add to shedule object
                this.setState({
                proposedschedule: [...this.state.proposedschedule, scheduleitem]
                });

                //get the remaining principle balance
                totalprinciple-=Number(principlepay)

                }

                this.setState({datarequested:false}) 
        }

    }


    //check if daily schedule is allowed
    if(this.state.showdailypay===true){
      console.log("Continue with daily schedules creation")

      var finalscheduleprinciple=0
      var finalscheduleinterest=0

      this.state.proposedschedule.map(
        (item)=>{
          finalscheduleprinciple+=Number(item.principle)
        })

      this.state.proposedschedule.map(
        (item)=>{
          finalscheduleinterest+=Number(item.interest)
        })
    

    //calculate daily pay
    var datetwo=''
        
    var dateMomentObjectone = moment(this.state.dateofissuancestring, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    //get last date
    this.state.proposedschedule.map((item)=>{
      if(Number(item.key)===Number(this.state.loanperiod)){
        console.log(item.date)
        datetwo=item.date
      }
      console.log(item)
    })

    var dateMomentObjecttwo = moment(datetwo, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjecttwo = dateMomentObjecttwo.toDate();

    var date1 = dateObjectone;
    var date2 = dateObjecttwo;

    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);


     var datesarray=[]
      //loop thrgh days to create dates
      var yr = date1.getFullYear();
      var mt = date1.getMonth();
      var dy = date1.getDate();

      for (let i = 1; i <= Difference_In_Days; i++) {

        var paydate=new Date(yr,mt, dy+i).toLocaleDateString('en-GB', {dy : 'numeric',mt : 'numeric',yr : 'numeric'}).split(' ').join('/');

        if (this.isSaturday(new Date(yr,mt, dy+i))===true && this.state.excludesundays){
          console.log(paydate+" is a saturday")
        }
        else if (this.isSunday(new Date(yr,mt, dy+i))===true && this.state.excludesaturdays){
          console.log(paydate+" is a sunday")
        }else{
          datesarray.push(paydate)
        }
      }


      var dailyprinciplepay=Number(finalscheduleprinciple)/Number(datesarray.length)
      var dailyinterestpay=Number(finalscheduleinterest)/Number(datesarray.length)
  
      var roundeddailyprinciplepay=Math.ceil(dailyprinciplepay/this.state.roundoffvalue)*this.state.roundoffvalue
      var roundeddailyinterestpay=Math.ceil(dailyinterestpay/this.state.roundoffvalue)*this.state.roundoffvalue
  

      //loop through the dates now
      var nnewwarray=[]
      var itemno=1

      datesarray.map((item)=>{

        var scheitem={
          "key":itemno,
          "date":item,
          "principlebefore":finalscheduleprinciple,
          "interestbefore":finalscheduleinterest,
          "principlepay":roundeddailyprinciplepay,
          "interestpay":roundeddailyinterestpay,
          "totalpay":roundeddailyprinciplepay+roundeddailyinterestpay,
          "principlebalance":finalscheduleprinciple-roundeddailyprinciplepay,
          "interestbalance":finalscheduleinterest-roundeddailyinterestpay
        }

        //push to array
        nnewwarray.push(scheitem)
        itemno+=1

        finalscheduleprinciple-=Number(roundeddailyprinciplepay)
        finalscheduleinterest-=Number(roundeddailyinterestpay)

      })

      console.log(nnewwarray)
      this.setState({dailyschedule:nnewwarray})
    
    }else {
      console.log("no daily shedule")


    }


  }

  //count decimales
   countDecimals =(value) =>{
    if (Math.floor(value) !== value)
        return value.toString().split(".")[1].length || 0;
    return 0;
    }

    //check if its weekend
     isSunday=(date)=> {
      return date.getDay() === 0;
    }

    isSaturday=(date)=> {
      return date.getDay() === 6;
    }


  handleloanamountChange= (e) => this.setState({ loanamount: e});
  handleinterestrateChange= (e) => this.setState({ interestrate: e.target.value});
  handleinterestratetypeChange= (e) => this.setState({ interestratetype: e});
  handleloanperiodChange= (e) => this.setState({ loanperiod: e.target.value});
  handledateofissuanceChange= (date, dateString) =>{
    //console.log(date.format(dateFormat))
    this.setState({ dateofissuance: date.format(dateFormat)});
    this.setState({dateofissuancestring:dateString})
  } 

  handleroundoffvalueChange= (e) => this.setState({ roundoffvalue: e.target.value});

  render(){
   
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
            <Row >
                  <Col xs="12" sm="6" lg="4">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>LOAN CALCULATOR FORM</h4>
                    <Form
                      onFinish={(event) => this.handleFormSubmit(event)} 
                    >

                      <FormItem label="Loan Amount"          
                      >
                        
                        <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={this.handleloanamountChange}
                      />  
                      
                      
                      </FormItem>

                      <FormItem label="Interest Rate"          
                      >
                        <Input name="interestrate"  placeholder="0" value={this.state.interestrate} onChange={this.handleinterestrateChange} />
                      </FormItem>

                      <FormItem label="Interest Rate Type"
                      
                      >
                          <Select placeholder="Interest Rate Type"  value={this.state.interestratetype} onChange={this.handleinterestratetypeChange} >
                                <Option value="flat">Flat Interest</Option>
                                <Option value="reducingbalance">Reducing</Option>
                          </Select>
                      </FormItem>

                      <FormItem label="Loan Period(Months)"          
                      >
                        <Input name="loanperiod"  placeholder="0" value={this.state.loanperiod} onChange={this.handleloanperiodChange} />
                      </FormItem>  

                      <FormItem label="Date Of Issuance"
                        name="dateofissuance"          
                     >
                          <DatePicker defaultValue={moment()} onChange={this.handledateofissuanceChange} format={dateFormat} />
                      </FormItem>

                      <FormItem label="Round Off Value"          
                      >
                        <Input name="roundoffvalue"  placeholder="0" value={this.state.roundoffvalue} onChange={this.handleroundoffvalueChange} />
                      </FormItem>  

                      <FormItem name="generatedailyschedule" label="Generate daily shedule" >
                        <Switch
                            checked={this.state.showdailypay}
                            onChange={val=>{
                              this.setState({showdailypay:!this.state.showdailypay})
                          }}
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                          />
                     </FormItem>

                    {
                      this.state.showdailypay?
                      <FormItem name="excludesaturdays" label="Exclude Saturdays on dailyschedule" >
                      <Switch
                          checked={this.state.excludesaturdays}
                          onChange={val=>{
                            this.setState({excludesaturdays:val})

                        }}
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                          />
                   </FormItem>                      
                      :
                      null
                    }



                     {
                      this.state.showdailypay?
                      <FormItem name="excludesundays" label="Exclude Sundays on dailyschedule" >
                      <Switch
                          checked={this.state.excludesundays}
                          onChange={val=>{
                            this.setState({excludesundays:val})
                         }}
                          checkedChildren="ON"
                          unCheckedChildren="OFF"
                          />
                      </FormItem>

                      :
                      null
                    }


                      <FormItem>
                        <Button  type="primary" htmlType="submit">Compute</Button>
                      </FormItem>
                    </Form>

                    </Card>
                    </Col>


                    <Col xs="12" sm="6" lg="8">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Expected Loan Monthly Schedule</h4>

                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.proposedschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.key}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebf} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principle} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateScheduleTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateInterestTotal()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bold'}}>{<CurrencyFormat value={this.calculateTotalPay()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td></td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>

                
                  <h5 style={{fontWeight:'bolder',color:'red'}}>NOTES</h5>
                  <h6>Flat Interest gives more profits as compared to reducing balance</h6>
                  <h6>Incase we round,we make sure loan principle balances at the last payment</h6>

                  {
                    this.state.showdailypay===true?
                    <div>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Expected Daily Schedule</h4>

                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Principle Bf</th>
                            <th>Interest Bf</th>
                            <th>Principle Pay</th>
                            <th>Interest Pay</th>
                            <th>Total Pay</th>
                            <th>Principle Bal</th>
                            <th>Interest Bal</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.dailyschedule.map(
                          (item)=>(
                            <tr>
                            <td>{item.key}</td>
                            <td>{item.date}</td>
                            <td>{<CurrencyFormat value={item.principlebefore} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interestbefore} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principlepay} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interestpay} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.totalpay} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>

                            </tr>
                          ))}

                      </tbody>
                  </reactstrp.Table>


                    </div>
                    :
                    null
                  }

                  </Card>
                  </Col>
            </Row>

        </div>
      );

    }

  }

}


export default LoanCalculator;
