import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }
      

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }
  
      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

    }


   //calculate total of issuance
   calculateTotalofIssuance=()=>{
    var totalofissuances=0
    this.props.loanissues.map(
      (item)=>{
        totalofissuances+=Number(item.approvedamount)
      })

    return totalofissuances;
   }


   //calculate total principle in
   calculateTotalPrincipleIn=()=>{
    var total=0
    this.props.loanrepayments.map(
      (item)=>{
        total+=Number(item.principle_paid)
      })

    return total;
   }

   calculateTotalInterestIn=()=>{
    var total=0
    this.props.loanrepayments.map(
      (item)=>{
        total+=Number(item.interest_paid)
      })

    return total;
   }

   calculateTotalTotalIn=()=>{
    var total=0
    this.props.loanrepayments.map(
      (item)=>{
        total+=Number(item.total_paid)
      })

    return total;
   }



   //calculate total principle defaulted
   calculateTotalPrincipledefaulted=()=>{
    var total=0
    this.props.loandefaults.map(
      (item)=>{
        total+=Number(item.principlebalance)
      })

    return total;
   }

   calculateTotalInterestdefaulted=()=>{
    var total=0
    this.props.loandefaults.map(
      (item)=>{
        total+=Number(item.interestbalance)
      })

    return total;
   }

   calculateTotalTotalDefaulted=()=>{
    var total=0
    this.props.loandefaults.map(
      (item)=>{
        total+=Number(item.totalbalance)
      })

    return total;
   }



  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>
       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>DAY LOAN REPORT FOR {this.props.date} </h3>
             <h4 style={{fontWeight:'bold'}}>Loan Repayments</h4>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Principle In</th>
                            <th>Interest In</th>
                            <th>Total In</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalPrincipleIn()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalInterestIn()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalTotalIn()} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>             


             <h4 style={{fontWeight:'bold'}}>Loan Defaults</h4>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loandefaults.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.totalbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalPrincipledefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalInterestdefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalTotalDefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          </tr>

                      </tbody>
                  </reactstrp.Table>  


             <h4 style={{fontWeight:'bold'}}>Loan Issues</h4>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Approved AMount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{<CurrencyFormat value={item.approvedamount} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalofIssuance()} displayType={'text'} thousandSeparator={true}/>}</td>                        
                          </tr>
                      </tbody>
                  </reactstrp.Table>   
           
        </div>
      );
    }
  }


class DayLoanReport extends React.Component {

  state = {
    users:[],
    loanrepayments:[],
    loanissues:[],
    loandefaults:[],
    dateone:moment().format(dateFormat).toString(),
    datarequested:false,
    companyprofile:{},
    staffs:[],
    creditofficer:'',

   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/staffs/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          staffs:res.data
        })
    })


  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString});
   } 


   //calculate total of issuance
   calculateTotalofIssuance=()=>{
    var totalofissuances=0
    this.state.loanissues.map(
      (item)=>{
        totalofissuances+=Number(item.approvedamount)
      })

    return totalofissuances;
   }


   //calculate total principle in
   calculateTotalPrincipleIn=()=>{
    var total=0
    this.state.loanrepayments.map(
      (item)=>{
        total+=Number(item.principle_paid)
      })

    return total;
   }

   calculateTotalInterestIn=()=>{
    var total=0
    this.state.loanrepayments.map(
      (item)=>{
        total+=Number(item.interest_paid)
      })

    return total;
   }

   calculateTotalTotalIn=()=>{
    var total=0
    this.state.loanrepayments.map(
      (item)=>{
        total+=Number(item.total_paid)
      })

    return total;
   }



   //calculate total principle defaulted
   calculateTotalPrincipledefaulted=()=>{
    var total=0
    this.state.loandefaults.map(
      (item)=>{
        total+=Number(item.principlebalance)
      })

    return total;
   }

   calculateTotalInterestdefaulted=()=>{
    var total=0
    this.state.loandefaults.map(
      (item)=>{
        total+=Number(item.interestbalance)
      })

    return total;
   }

   calculateTotalTotalDefaulted=()=>{
    var total=0
    this.state.loandefaults.map(
      (item)=>{
        total+=Number(item.totalbalance)
      })

    return total;
   }


 
   //search sales method
   searchSales=()=>{

    if(this.state.companyprofile.attach_officer_to_loan===true){
      let form_data = new FormData();
      form_data.append('date', this.state.dateone);
      form_data.append('sacco', sacco);
      form_data.append('creditofficer', this.state.creditofficer);

      if(this.state.dateone===''){
        message.info("Please select date")
      }else if(this.state.creditofficer===''){
        message.info("Credit Officer Missing")
      }
      else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getDayLoanReportperofficer', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
            this.setState({loandefaults:JSON.parse(res.data.loandfaults)})
            this.setState({loanissues:JSON.parse(res.data.loanissues)})
            this.setState({loanrepayments:JSON.parse(res.data.loanrepayments)})
          } 
          )
          .catch(error => console.log(error))   
    }


    }else{

      let form_data = new FormData();
      form_data.append('date', this.state.dateone);
      form_data.append('sacco', sacco);
      
      if(this.state.dateone===''){
        alert("Please select date")
      }else{
          this.setState({datarequested:true})
  
          //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/getDayLoanReport', form_data,{
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
          .then(res =>{
            this.setState({datarequested:false})
            this.setState({loandefaults:JSON.parse(res.data.loandfaults)})
            this.setState({loanissues:JSON.parse(res.data.loanissues)})
            this.setState({loanrepayments:JSON.parse(res.data.loanrepayments)})
          } 
          )
          .catch(error => console.log(error))   
    }

    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              date={this.state.dateone} 
              loandefaults={this.state.loandefaults} 
              loanissues={this.state.loanissues} 
              loanrepayments={this.state.loanrepayments} 
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="DAY LOAN REPORT" key="1">

              {
                this.state.companyprofile.attach_officer_to_loan===true?
                <Form  layout="inline" >
                <FormItem label="Date (Day)">
                <DatePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem label="Credit Officer"
                        name="creditofficer"
                        rules={[
                          {
                            required: false,
                            message: 'Please Select creditofficer',
                          },
                        ]}
                      >
                      <Select placeholder="Credit Officer"  value={this.state.creditofficer} onChange={(val)=>{this.setState({creditofficer:val})}} >
                        <Option value={0}>All Officers</Option>
                        {this.state.staffs.map(
                          (ctgry)=>(
                            <Option value={ctgry.id}>{ctgry.full_Name}</Option>
                          ))}
                      </Select>
                  </FormItem>

                <FormItem>
                <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                </FormItem> 
                </Form>
                :
                <Form  layout="inline" >
                <FormItem label="Date (Day)">
                <DatePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                </FormItem> 
                </Form>
              }

 

  
  
             <br></br>
             <h4>Loan Repayments</h4>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Principle In</th>
                            <th>Interest In</th>
                            <th>Total In</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanrepayments.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{<CurrencyFormat value={item.principle_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interest_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.total_paid} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalPrincipleIn()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalInterestIn()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalTotalIn()} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>             


             <h4>Loan Defaults</h4>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Principle Balance</th>
                            <th>Interest Balance</th>
                            <th>Total Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loandefaults.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{<CurrencyFormat value={item.principlebalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.interestbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.totalbalance} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalPrincipledefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalInterestdefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalTotalDefaulted()} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          </tr>

                      </tbody>
                  </reactstrp.Table>  


             <h4>Loan Issues</h4>
             <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Account Name</th>
                            <th>Account Number</th>
                            <th>Approved AMount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.state.loanissues.map(
                          (item)=>(
                            <tr>
                            <td>{item.date}</td>
                            <td>{item.accountName}</td>
                            <td>{item.accountNumber}</td>
                            <td>{<CurrencyFormat value={item.approvedamount} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalofIssuance()} displayType={'text'} thousandSeparator={true}/>}</td>                        
                          </tr>

                      </tbody>
                  </reactstrp.Table> 

              <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}> 
              <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>  
              </Panel>
              </Collapse>
  
  
          </div>
  
      )        


      }

 
 
   
   }
}

export default DayLoanReport; 
