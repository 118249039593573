import React from 'react';
import { Form, Input, Button,Spin,Select, message } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var sacco= ''
var bizuserid= ''

class BankSafeTransferForm extends React.Component {

  state = {
    bankname:'' ,  
    datarequested:false,
    bankaccounts:[],
    bankaccount:'',
    tranfer_mode:'',
    transfer_amount:0,
    bankaccountobject:{},
    sacco_object:{}

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/bankaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          bankaccounts:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/bankaccounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          bankaccounts:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
        this.setState({
          sacco_object:res.data
        })
    })

  
  }


  //submit button pressed
  handleFormSubmit=(event) =>{

    let form_data = new FormData();
    form_data.append('date', moment().format(dateFormat).toString());
    form_data.append('tranfer_mode', this.state.tranfer_mode);
    form_data.append('transfer_amount', this.state.transfer_amount);
    form_data.append('sacco', sacco);
    form_data.append('bank', this.state.bankaccount);
    form_data.append('user', bizuserid);

    if(this.state.tranfer_mode==="bank_safe"){
      form_data.append('money_from', 'bank');
      form_data.append('money_to', 'safe');
    }else{
        form_data.append('money_from', 'safe');
        form_data.append('money_to', 'bank');
    }

    if(this.state.tranfer_mode==="bank_safe" && (Number(this.state.bankaccountobject.balance)<Number(this.state.transfer_amount))){
      message.info("Amount is no available in the bank account")
    }else if(this.state.tranfer_mode==="safe_bank" && (Number(this.state.sacco_object.safe_balance)<Number(this.state.transfer_amount))){
      message.info("Amount not available in safe")
    }else{
    //Now submit sale data to database
    this.setState({datarequested:true})

    axios.post(serverconfig.backendserverurl+'/api/safebanktransfers/', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{

        let form_datai = new FormData();
        form_datai.append('tranfer_mode', this.state.tranfer_mode);
        form_datai.append('transfer_amount', this.state.transfer_amount);
        form_datai.append('sacco', sacco);
        form_datai.append('bank', this.state.bankaccount);
  
        axios.post(serverconfig.backendserverurl+'/customqueries/updatebanksafetransferamounts', form_datai, {
          headers: {
            'content-type': 'multipart/form-data'
          }
          })
        .then(res => {
          console.log(res.data)
          window.location.reload(false)
          
        })
        .catch(error => console.log(error))

        this.setState({datarequested:false})
        message.info("Successful transfer")

      } 
      )
      .catch(error => console.log(error))
    }

  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return (
        <div>
    <Form
        onFinish={(event) => this.handleFormSubmit(event)}
    >
      <FormItem label="Bank Account"
        name="bankaccount"
        rules={[
          {
            required: true,
            message: 'Please select bank account',
          },
        ]}
      >

      <Select placeholder="Bank Account" value={this.state.bankaccount} 
      onChange={(val)=>{
        this.setState({bankaccount:val})
        axios.get(`${serverconfig.backendserverurl}/api/bankaccounts/${val}`)
        .then(res => {  
            this.setState({bankaccountobject: res.data})
        })
      }}
       >
        {this.state.bankaccounts.map(
          (item)=>(
            <Option value={item.id}> {item.bankname} | {item.accountnumber} | Balance: {item.balance} </Option>
          ))}
      </Select>

      </FormItem>

      <FormItem label="Tranfer Mode"
        name="tranfer_mode"
        rules={[
          {
            required: true,
            message: 'Please select transfer mode',
          },
        ]}
      >

      <Select placeholder="Transfer Mode" value={this.state.tranfer_mode} onChange={(val)=>{this.setState({tranfer_mode:val})}} >
            <Option value={'bank_safe'}> Bank - Safe </Option>
            <Option value={'safe_bank'}> Safe -Bank</Option>
      </Select>

      </FormItem>

      <h3>SAFE BALANCE: { this.state.sacco_object.safe_balance}</h3>
      <FormItem label="Transfer Amount"
        name="transfer_amount"
        rules={[
          {
            required: true,
            message: 'Please input tranfer amount',
          },
        ]}    
      >
        <Input  type={'number'} name="transfer_amount"  placeholder="Transfer Amount" value={this.state.transfer_amount} onChange={(val)=>this.setState({transfer_amount:val.target.value})} />
      </FormItem>
     
      <FormItem>
        <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
      </FormItem>
    </Form>
   </div>
     );

    }

  }

}


export default BankSafeTransferForm;



