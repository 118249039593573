import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var bizuserid= ''
class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }
  
    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>ACCOUNT TRANSACTIONS REPORT ( {this.props.channel} ) FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>  

             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Debit(Withdraw)</th>
                          <th>Credit(Deposit)</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.time_stamp}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totaldebit} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.props.totalcredit} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>

             
        </div>
      );
    }
  }


class TransactionReportPerChannel extends React.Component {

  state = {
    users:[],
    sheetitems:[],
    dateone:'',
    datetwo:'',
    channel:'',
    channelname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handlechannelChange= (e) => this.setState({ channel: e});

 
   //search sales method
   searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('channel', this.state.channel);
    form_data.append('sacco', sacco);
    
    console.log(this.state.channel)
      
    if(this.state.channel===''||this.state.dateone===''||this.state.datetwo===''){
      alert("Please select channel or dates missing")
    }else{
        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/gettransactionsreportperchannel', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({datarequested:false})

            this.setState({sheetitems:JSON.parse(res.data.report)})
            this.setState({totaldebit:res.data.totalDebit})
            this.setState({totalcredit:res.data.totalCredit})
            this.setState({channelname:res.data.channelname})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
   
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              channel={this.state.channel}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header={" ACCOUNT TRANSACTIONS REPORT ( "+this.state.channel+" )"} key="1">
              <Form  layout="inline" >
 
              <FormItem label="Channel">
                     <Select 
                     placeholder="Channel"
                     style={{ width: 200 }} 
                     value={this.state.channel} 
                     onChange={this.handlechannelChange}
                      
                     showSearch
                     optionFilterProp="children"
                     onFocus={this.onFocus}
                     onBlur={this.onBlur}
                     onSearch={this.onSearch}
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }                     
                      >
                        <Option value='Office'>Office Transactions</Option>
                        <Option value='mmapp'>MM App</Option>
                        <Option value='mmussd'>MM USSD</Option>
                     </Select>
                 </FormItem>
 
 
                  <FormItem label="Date Range">
                      <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                  </FormItem>
  
                  <FormItem>
                  <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                  </FormItem> 
  
             </Form>
  
             <br></br>
             <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Desc</th>
                          <th>Debit(Withdraw)</th>
                          <th>Credit(Deposit)</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.time_stamp}</td>
                          <td>{item.account}</td>
                          <td>{item.description}</td>
                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totaldebit} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.state.totalcredit} displayType={'text'} thousandSeparator={true}/>}</td>
                        </tr>

                    </tbody>
                </reactstrp.Table>    

                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

              </Panel>
              </Collapse>
  
          </div>
  
      )        

      }

   }
}

export default TransactionReportPerChannel; 
