import React from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { RedoOutlined,SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});



var sacco= ''
var username=''
var token= ''
var bizuserid= ''


class LoggedInList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    accounts: [],
   
  };


  //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    if(String(id)===String(bizuserid)){
      message.info("Cant activate self")

    }else{
      
      let form_data = new FormData();
      form_data.append('userid', id);

    //send request to delete the logged in LoggedInAccounts
    //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/deleteusersession', form_data,{
      headers: {
          'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        message.info("Successfully reactivated login")

        let form_data = new FormData();
        form_data.append('sacco', sacco);
              
        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getbranchloggedinusers', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          this.setState({accounts:JSON.parse(res.data.loggedinaccounts)})
        } 
        )
        .catch(error => console.log(error))   

      } 
      )
      .catch(error => console.log(error))  
    }


  };




  componentDidMount(){

    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
      bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);
          
    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/getbranchloggedinusers', form_data,{
    headers: {
      'content-type': 'multipart/form-data'
    }
    })
    .then(res =>{
      this.setState({accounts:JSON.parse(res.data.loggedinaccounts)})
      this.setState({datarequested:false})
    } 
    )
    .catch(error => console.log(error))   

}

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [

      {
        title: 'User Name',
        dataIndex: 'username',
        key: 'id',
        ...this.getColumnSearchProps('username'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'id',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>

        <Popover content={<p>Click here to re activate user login</p>} title="Reactivate Login">
        <Popconfirm title="Sure to activate?" onConfirm={() => this.handleDelete(text)}>
          <RedoOutlined style={{color:'green'}}/>
         </Popconfirm>
        </Popover>
       
        </p>,
      },

    ];

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL LOGGED IN ACCOUNTS" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.accounts} bordered/>
            </Panel>
            </Collapse>

        </div>
    )
    }
   
  }
}

export default LoggedInList; 
