import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
     }else{
        token= ''
     }

     if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
      })

    }


    render() {
      return (
        <div style={{padding:20}}>
 <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>

       </Row>
             <br></br> 
             <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',strong:true}}>{this.props.sacconame} SMS Usage Report from {this.props.dateone}  to {this.props.datetwo}</h2>

             <reactstrp.Table bordered>
             <thead>
                <tr>
                  <th>Date</th>
                  <th>Phone</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
              {this.props.reportitems.map(
                (item)=>(
                  <tr>
                  <td>{item.date}</td>
                  <td>{item.number}</td>
                  <td>{item.message}</td>
                  </tr>
                ))}
                 <tr>                                      
                  <th></th>                                    
                  <th></th>     
                  <th>
                  <h4><strong>Total Number Of Messages: {this.props.totalsms}  </strong>  </h4>
                  <h4><strong>Current Remaining Total: {this.props.remainingtotal}  </strong>  </h4>

                  </th>                      
                  </tr>  
              </tbody>
            </reactstrp.Table>

        </div>
      );
    }
  }


class SaccoSmsUsagestatementreport extends React.Component {

  state = {
    reportitems:[],
    dateone:'',
    datetwo:'',
    sacco:'',
    sacconame:'',
    totalsmscost:0,
    totalsms:0,
    datarequested:true,
    country:'',
    currency:'',
    remainingtotal:0
   };

   componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
    }else{
        token= ''
    }

   if(localStorage.getItem("sacco")){
    sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
 
    }else{
        sacco= ''
        bizuserid= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})

  }
 
  handledatechange= (date, dateString) =>{
    this.setState({ dateone: dateString[0]});
    this.setState({ datetwo: dateString[1]});
  } 


 
   //search sales method
   searchSales=()=>{
    this.setState({reportitems:[]})
    this.setState({totalsmscost:0})
    this.setState({totalsms:0})
    this.setState({remainingtotal:0})

     //set loading state
     this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('sacco',sacco);

      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getsaccosmsstatement', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
          this.setState({datarequested:false})
          this.setState({reportitems:JSON.parse(res.data.report)})
          this.setState({totalsmscost:res.data.totalsmscost})
          this.setState({totalsms:res.data.totalsms})
          this.setState({remainingtotal:res.data.remainingtotal})

      } 
      )
      .catch(error => console.log(error))   
    
    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });
  
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };
  
  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  



   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),

      },      
      {
        title: 'Phone',
        dataIndex: 'number',
        key: 'id',
        ...this.getColumnSearchProps('number'),

      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'id',
      },

    ];
 
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalsms={this.state.totalsms} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            columns={columns}
            reportitems={this.state.reportitems}

            ref={el => (this.componentRef = el)} /> 
          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="SMS USAGE REPORT" key="1">
            <Form  layout="inline" >

              <FormItem label="Date Range">
                  <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
              </FormItem> 

           </Form>

           <br></br>
            <Table columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.reportitems}
            scroll={{ x: 1000 }}
           bordered/>  
            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />

            <div style={{overflowX:'auto'}}>
            <Space>

               <h4>TOTAL SMS MESSAGES: {this.state.totalsms}  </h4>
                &nbsp;&nbsp;
                <h4><strong>Current Remaining Total: {this.state.remainingtotal}  </strong>  </h4>
                &nbsp;&nbsp;

                <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
            </Space>
           </div>

          </Panel>
          </Collapse>

        </div>

    )
    }
 
     
   }
}

export default SaccoSmsUsagestatementreport; 
