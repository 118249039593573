import React from 'react';
import { Form, Input, Button,Select,Spin,Card,Table,Collapse, message,Popover,Popconfirm,Tabs } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import { UserOutlined,SearchOutlined, LockOutlined,SwapOutlined,CloseCircleOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import Highlighter from 'react-highlight-words';

var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Panel } = Collapse;

var sacco= ''
var token= ''
var bizuserid= ''


class InterBranchMoneyTransferForm extends React.Component {

  state = {
    transfer_amount:0,
    receiving_branch:'',
    receiving_account:'',
    branches:[],
    companyprofile:{},
    userprofile:{},
    receivingbranchaccounts:[],
    receiving_account_rights:{},
    userrights:{},
    transfer_reason:'',
    mytransfers:[],
    acceptedtranfers:[],
    rejectedtransfers:[]
    

  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }
    
    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${bizuserid}`)
    .then(res => {
        this.setState({
          userrights:res.data[0]
        })
    })

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
      
    })

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
 
        //get branches
        axios.get(`${serverconfig.backendserverurl}/api/saccos/?supercompany=${res.data.supercompany}`)
        .then(res => {  
            this.setState({
              branches: res.data
            })
        })
 
        this.setState({datarequested:false})
 
    })

    axios.get(serverconfig.backendserverurl+`/api/interbranchmoneytransfers/?receiving_account=${bizuserid}&status=${'unknown'}`)
    .then(res => {
        this.setState({
          mytransfers:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/interbranchmoneytransfers/?receiving_account=${bizuserid}&status=${'received'}`)
    .then(res => {
        this.setState({
          acceptedtranfers:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/interbranchmoneytransfers/?receiving_account=${bizuserid}&status=${'rejected'}`)
    .then(res => {
        this.setState({
          rejectedtransfers:res.data
        })
    })

  }


  //submit button pressed
  handlemoneytransferFormSubmit=(event) =>{

    let form_data = new FormData();
    form_data.append('sending_account', bizuserid);
    form_data.append('receiving_account', this.state.receiving_account);
    form_data.append('transfer_amount', this.state.transfer_amount);
    form_data.append('sending_branch', sacco);
    form_data.append('receiving_branch', this.state.receiving_branch);
    form_data.append('transfer_reason', this.state.transfer_reason);

    if(this.state.receiving_branch===''){
      alert("Receiving branch is empty")
    }else if(this.state.receiving_account===''){
      alert("Receiving account is empty")
    }else if(Number(this.state.userprofile.User_balance)<Number(this.state.transfer_amount))
    alert("You have less user balance")
    else{

      if(this.state.receiving_account_rights.receive_interbranchmoney===true){
        this.setState({datarequested:true})
  
        axios.post(serverconfig.backendserverurl+'/customqueries/receiveinterbranchtranfermoney', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        message.info("Successfull Tranfer of Money")
        this.setState({datarequested:false})
      }
    
      )
      .catch(error => console.log(error))
      }else{
        alert("Account has no right to receive money");
      }
    }


  }

  //handle branch change
  handlereceiving_branchChange=(e)=>{
    this.setState({receivingbranchaccounts:[]})
    this.setState({receiving_account:''})

    this.setState({datarequested:true})

    this.setState({receiving_branch:e})

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${e}`)
    .then(res => {
        this.setState({
          receivingbranchaccounts:res.data
        })

        this.setState({datarequested:false})
    })

  }

//receiving account change
  handlereceiving_accountChange=(e)=>{
    this.setState({receiving_account_rights:{}})

    this.setState({datarequested:true})

    this.setState({receiving_account:e})

    axios.get(serverconfig.backendserverurl+`/api/accountrights/?user=${e}`)
    .then(res => {
        this.setState({
          receiving_account_rights:res.data[0]
        })

        this.setState({datarequested:false})
    })


  }

  handletransfer_amountChange= (e) => this.setState({ transfer_amount: e.target.value});
  handletransfer_reasonChange= (e) => this.setState({ transfer_reason: e.target.value});



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};


  render(){

    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',

      },
      {
        title: 'Sender Branch',
        dataIndex: 'sendingbranchname',
        key: 'id',
        ...this.getColumnSearchProps('sendingbranchname'),

      },
      {
        title: 'Sender Account',
        dataIndex: 'sendingaccountname',
        key: 'id',
        ...this.getColumnSearchProps('sendingaccountname'),
      },
      {
        title: 'Amount',
        dataIndex: 'transfer_amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to Receive Money</p>} title="Receive">
        <Popconfirm title="Sure to receive?" onConfirm={() => {
              this.setState({datarequested:true})
              let form_data = new FormData();
              form_data.append('transferid', text);
              axios.post(serverconfig.backendserverurl+'/customqueries/acceptinterbranchtransfermoney', form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
            .then(res => {
              message.info("Successfull")
              this.setState({datarequested:false})
              window.location.reload(false)
            }
          
            )
            .catch(error => console.log(error))

        }}>
          <SwapOutlined style={{color:'#154360'}}/>
         </Popconfirm>
        </Popover>
        &nbsp;&nbsp;&nbsp;

        <Popover content={<p>Click here to Reject Request</p>} title="Reject">
          <CloseCircleOutlined style={{color:'red'}} onClick={() => {
              this.setState({datarequested:true})
              let form_data = new FormData();
              form_data.append('transferid', text);
              axios.post(serverconfig.backendserverurl+'/customqueries/rejectinterbranchtransfermoney', form_data, {
                headers: {
                  'content-type': 'multipart/form-data'
                }
              })
            .then(res => {
              message.info("Successfull")
              this.setState({datarequested:false})
              window.location.reload(false)
            }
          
            )
            .catch(error => console.log(error))

          }}/>
        </Popover>
        </p>,
      },

    ];


    const acceptedtranfercolumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',

      },
      {
        title: 'Sender Branch',
        dataIndex: 'sendingbranchname',
        key: 'id',
        ...this.getColumnSearchProps('sendingbranchname'),

      },
      {
        title: 'Sender Account',
        dataIndex: 'sendingaccountname',
        key: 'id',
        ...this.getColumnSearchProps('sendingaccountname'),
      },
      {
        title: 'Amount',
        dataIndex: 'transfer_amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

    ];


    const rejectedtranfercolumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',

      },
      {
        title: 'Sender Branch',
        dataIndex: 'sendingbranchname',
        key: 'id',
        ...this.getColumnSearchProps('sendingbranchname'),

      },
      {
        title: 'Sender Account',
        dataIndex: 'sendingaccountname',
        key: 'id',
        ...this.getColumnSearchProps('sendingaccountname'),
      },
      {
        title: 'Amount',
        dataIndex: 'transfer_amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

    ];    


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      return (
        <div>
        {
            this.state.userrights.receive_interbranchmoney===true?
              <Card>

              <Tabs  type="card">
                    <TabPane tab="Pending Transfers" key="1" defaultActiveKey="1">
                        <Collapse onChange={this.callback} defaultActiveKey={['1']}>
                          <Panel header="Pending Transfers" key="1">
                          <Table   
                          columns={columns} 
                          scroll={{ x: 1000 }}
                          dataSource={this.state.mytransfers} 
                          pagination={{showQuickJumper:true,showSizeChanger:true }}
                          bordered/>
                          </Panel>
                        </Collapse>            
                    </TabPane>
      
                    <TabPane tab="Accepted Transfers" key="2" >
                        <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                          <Panel header="Accepted Transfers" key="1">
                          <Table columns={acceptedtranfercolumns} 
                          pagination={{showQuickJumper:true,showSizeChanger:true }}
                          dataSource={this.state.acceptedtranfers}
                          scroll={{ x: 1000 }}
                          bordered/>
                          </Panel>
                        </Collapse>
                    </TabPane>
      
                    <TabPane tab="Rejected Transfers" key="3" >
                        <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                          <Panel header="Rejected Transfers" key="1">
                          <Table columns={rejectedtranfercolumns} 
                          pagination={{showQuickJumper:true,showSizeChanger:true }}
                          dataSource={this.state.rejectedtransfers}
                          scroll={{ x: 1000 }}
                          bordered/>
                          </Panel>
                        </Collapse>         
                    </TabPane>
      

      
            </Tabs>






              </Card>
              :
              null
             }
             <br></br>
          
          {
            this.state.userrights.send_interbranchmoney===true?
            <Card>
            <h4>Money Tranfer Form</h4>
            <h5>User Balance:<CurrencyFormat value={this.state.userprofile.User_balance} displayType={'text'} thousandSeparator={true}  /> </h5>
              <Form
              onFinish={(event) => this.handlemoneytransferFormSubmit(event)} 
              >
              <FormItem label="Receiving Branch">
                  <Select 
                  placeholder="Branch Name" 
                  style={{  }} 
                  value={this.state.receiving_branch}
                  onChange={this.handlereceiving_branchChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.branches.map(
                      (accnt)=>(
                        Number(accnt.id)===Number(sacco)?
                        null:
                        <Option value={accnt.id}>{accnt.branchname}</Option>
                      ))}
                  </Select>
              </FormItem>


              <FormItem label="Receiving Branch Account">
                  <Select 
                  placeholder="Account Name" 
                  style={{  }} 
                  value={this.state.receiving_account}
                  onChange={this.handlereceiving_accountChange} 
                  showSearch
                  optionFilterProp="children"
                  onFocus={this.onFocus}
                  onBlur={this.onBlur}
                  onSearch={this.onSearch}
                                        
                  >
                    {this.state.receivingbranchaccounts.map(
                      (accnt)=>(
                        <Option value={accnt.id}>{accnt.username}</Option>
                      ))}
                  </Select>
              </FormItem>


            <FormItem label="Transfer Amount"
              name="transfer_amount"
              rules={[
                {
                  required: true,
                  message: 'Please enter value',
                },
              ]}           
            >
              <Input name="transfer_amount"  placeholder="0.00" value={this.state.transfer_amount} onChange={this.handletransfer_amountChange} />
            </FormItem>

  
            <FormItem label="Transfer Reason"
              name="transfer_reason"
              rules={[
                {
                  required: true,
                  message: 'Please enter reason',
                },
              ]}           
            >

                <TextArea
                    placeholder="Transfer Reason" 
                    autoSize={{ minRows: 2, maxRows: 6 }}
                    value={this.state.transfer_reason}
                    onChange={this.handletransfer_reasonChange}
                  />

            </FormItem>
  
            <FormItem>
              <Button  type="primary" htmlType="submit">Transfer</Button>
            </FormItem>
          </Form>    
              
          </Card>

            :
            null
          
          
             }


             
              
              

          
        </div>
      );

    }
  }

}


export default InterBranchMoneyTransferForm;



