import React from 'react';
import MemberForm from '../components/MemberForm'
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table, Input, Button,Collapse,Typography,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin,Tabs } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DownloadOutlined,PrinterOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
import ReactExport from "@ibrahimrahmani/react-export-excel";

var CryptoJS = require("crypto-js");

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;
const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    }

        //calculate total savings
        calculateTotalSavings=()=>{
          var Total=0
          this.props.members.map(
            (item)=>{
              Total+=Number(item.UserBalance)
            })
      
          return Total;

        }


        //calculate total shares number
        calculateTotalShares_number=()=>{
          var Total=0
          this.props.members.map(
            (item)=>{
              Total+=Number(item.sharesnumber_balance)
            })
      
          return Total;
    
        }

          //calculate total shares number
          calculateTotalShares_amount=()=>{
            var Total=0
            this.props.members.map(
              (item)=>{
                Total+=Number(item.sharesamount_balance)
              })
        
            return Total;
      
          }
        
          calculateTotal_loanamount=()=>{
            var Total=0
            this.props.members.map(
              (item)=>{
                Total+=Number(item.loan_balance)
              })
        
            return Total;
      
          }


          //new
          calculateTotal_loansavingssecurity=()=>{
            var Total=0
            this.props.members.map(
              (item)=>{
                Total+=Number(item.savingsloan_security_balance)
              })
        
            return Total;
      
          }

          calculateTotal_loansharessecurity=()=>{
            var Total=0
            this.props.members.map(
              (item)=>{
                Total+=Number(item.shares_security_balance)
              })
        
            return Total;
          }

          calculateTotal_loanfines=()=>{
            var Total=0
            this.props.members.map(
              (item)=>{
                Total+=Number(item.loanfine_balance)
              })
        
            return Total;
          }


  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
              
                </Col>

     </Row>

           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>MEMBER BALANCES REPORT</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Account Type</th>
                          <th>Savings balance</th>
                          <th>Shares #</th>
                          <th>Shares Amount</th>
                          <th>Loan balance</th>

                          <th>Savings loan security (Reserve)</th>
                          <th>Shares loan security</th>
                          <th>Accrued Loan fine</th>

                          {
                            this.state.companyprofile.showwelfare?
                            <th>Welfare</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th>Emergency</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th>Dream</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th>Legacy</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th>Children</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th>University</th>
                            :
                            null
                          }

                        </tr>
                      </thead>
                      <tbody>
                      {this.props.members.map(
                        (item)=>(
                          <tr>
                          <td>{item.AccountNumber}</td>
                          <td>{item.FullName}</td>
                          <td>{item.accounttypename}</td>
                          <td>{<CurrencyFormat value={item.UserBalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.sharesnumber_balance} </td>
                          <td>{<CurrencyFormat value={item.sharesamount_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          
                          <td>{<CurrencyFormat value={item.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          <td>{<CurrencyFormat value={item.shares_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          <td>{<CurrencyFormat value={item.loanfine_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         


                          {
                            this.state.companyprofile.showwelfare?
                            <th>{item.welfare_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th>{item.emergency_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th>{item.dream_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th>{item.legacy_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th>{item.children_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th>{item.university_balance}</th>
                            :
                            null
                          }

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalSavings()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalShares_number()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalShares_amount()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loanamount()} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loansavingssecurity()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loansharessecurity()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loanfines()} displayType={'text'} thousandSeparator={true}/>}</td>

                  
                        {
                            this.state.companyprofile.showwelfare?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totalwelfare} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totalemergency} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totaldream} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totallegacy} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totalchildren} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totaluniversity} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null
                          }

                        </tr>

                    </tbody>
                </reactstrp.Table>    

                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>   
           
      </div>
    );
  }
}


class MemberSavingsReport_shareholder extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    members: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    totalshares:0,
    totalwelfare:0,
    totalemergency:0,
    totaldream:0,
    totallegacy:0,
    totalchildren:0,
    totaluniversity:0,


  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/shareholder_members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})

        const total = res.data.reduce((Total, item) => Total + item.shares, 0);
        this.setState({totalshares:total})

        this.setState({totalwelfare:res.data.reduce((Total, item) => Total + item.welfare_balance, 0)})
        this.setState({totalemergency:res.data.reduce((Total, item) => Total + item.emergency_balance, 0)})
        this.setState({totaldream:res.data.reduce((Total, item) => Total + item.dream_balance, 0)})
        this.setState({totallegacy:res.data.reduce((Total, item) => Total + item.legacy_balance, 0)})
        this.setState({totalchildren:res.data.reduce((Total, item) => Total + item.children_balance, 0)})
        this.setState({totaluniversity:res.data.reduce((Total, item) => Total + item.university_balance, 0)})

    })

}

        //calculate total savings
        calculateTotalSavings=()=>{
          var Total=0
          this.state.members.map(
            (item)=>{
              Total+=Number(item.UserBalance)
            })
      
          return Total;

        }


        //calculate total shares number
        calculateTotalShares_number=()=>{
          var Total=0
          this.state.members.map(
            (item)=>{
              Total+=Number(item.sharesnumber_balance)
            })
      
          return Total;
    
        }

          //calculate total shares number
          calculateTotalShares_amount=()=>{
            var Total=0
            this.state.members.map(
              (item)=>{
                Total+=Number(item.sharesamount_balance)
              })
        
            return Total;
      
          }
        
          calculateTotal_loanamount=()=>{
            var Total=0
            this.state.members.map(
              (item)=>{
                Total+=Number(item.loan_balance)
              })
        
            return Total;
      
          }

          //new
          calculateTotal_loansavingssecurity=()=>{
            var Total=0
            this.state.members.map(
              (item)=>{
                Total+=Number(item.savingsloan_security_balance)
              })
        
            return Total;
      
          }

          calculateTotal_loansharessecurity=()=>{
            var Total=0
            this.state.members.map(
              (item)=>{
                Total+=Number(item.shares_security_balance)
              })
        
            return Total;
          }

          calculateTotal_loanfines=()=>{
            var Total=0
            this.state.members.map(
              (item)=>{
                Total+=Number(item.loanfine_balance)
              })
        
            return Total;
          }


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});


  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>           </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totalsavings={this.calculateTotalSavings()} 
              members={this.state.members}
              totalshares={this.state.totalshares}
              totalwelfare={this.state.totalwelfare}
              totalemergency={this.state.totalemergency}
              totaldream={this.state.totaldream}
              totallegacy={this.state.totallegacy}
              totalchildren={this.state.totalchildren}
              totaluniversity={this.state.totaluniversity}
              ref={el => (this.componentRef = el)} /> 
             </div>

          
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}> MEMBER BALANCES REPORT</h4>} key="1">
           
            <div style={{overflowX : 'auto'}}>

            <reactstrp.Table bordered >
                      <thead>
                        <tr>
                          <th>Account Number</th>
                          <th>Account Name</th>
                          <th>Account Type</th>

                          <th>Savings balance</th>
                          <th>Shares #</th>
                          <th>Shares Amount</th>
                          <th>Loan balance</th>

                          <th>Savings loan security (Reserve)</th>
                          <th>Shares loan security</th>

                          <th>Accrued Loan fine</th>
                       
                          {
                            this.state.companyprofile.showwelfare?
                            <th>Welfare</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th>Emergency</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th>Dream</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th>Legacy</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th>Children</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th>University</th>
                            :
                            null
                          }

                        </tr>
                      </thead>
                      <tbody>
                      {this.state.members.map(
                        (item)=>(
                          <tr>
                          <td>{item.AccountNumber}</td>
                          <td>{item.FullName}</td>
                          <td>{item.accounttypename}</td>
                          
                          <td>{<CurrencyFormat value={item.UserBalance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{item.sharesnumber_balance} </td>
                          <td>{<CurrencyFormat value={item.sharesamount_balance} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.loan_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          
                          <td>{<CurrencyFormat value={item.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         
                          <td>{<CurrencyFormat value={item.shares_security_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         

                          <td>{<CurrencyFormat value={item.loanfine_balance} displayType={'text'} thousandSeparator={true}/>}</td>                         


                          {
                            this.state.companyprofile.showwelfare?
                            <th>{item.welfare_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th>{item.emergency_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th>{item.dream_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th>{item.legacy_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th>{item.children_balance}</th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th>{item.university_balance}</th>
                            :
                            null
                          }

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalSavings()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalShares_number()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalShares_amount()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loanamount()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loansavingssecurity()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loansharessecurity()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotal_loanfines()} displayType={'text'} thousandSeparator={true}/>}</td>

                  
                        {
                            this.state.companyprofile.showwelfare?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totalwelfare} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showemergency?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totalemergency} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showdream?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totaldream} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showlegacy?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totallegacy} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showchildren?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totalchildren} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null

                          }

                          {
                            this.state.companyprofile.showuniversity?
                            <th style={{fontWeight:'bolder'}}><CurrencyFormat value={this.state.totaluniversity} displayType={'text'} thousandSeparator={true}/></th>
                            :
                            null
                          }

                        </tr>

                    </tbody>
                </reactstrp.Table>       
            </div>
       
           
                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              &nbsp;&nbsp;&nbsp;

              <ExcelFile  element={<Button type='primary' icon={<DownloadOutlined/>}>Download Member Balances report</Button>}>
                <ExcelSheet data={this.state.members} name="Member balances report">
                    <ExcelColumn label="Acc No" value="AccountNumber"/>
                    <ExcelColumn label="Acc Name" value="FullName"/>
                    <ExcelColumn label="Account type" value="accounttypename"/>
                    <ExcelColumn label="Ordinary savings" value="UserBalance"/>
                    <ExcelColumn label="Share no" value="sharesnumber_balance"/>
                    <ExcelColumn label="Share amount" value="sharesamount_balance"/>
                    <ExcelColumn label="Loan amount" value="loan_balance"/>

                    <ExcelColumn label="Savings loan security (Reserve)" value="savingsloan_security_balance"/>
                    <ExcelColumn label="Shares loan security" value="shares_security_balance"/>

                    <ExcelColumn label="Loan fine" value="loanfine_balance"/>

                </ExcelSheet>
            </ExcelFile>
          </div>

        </Panel>
      </Collapse>
            


      </div>
    )
  }

  }
}

export default MemberSavingsReport_shareholder; 
