import React from 'react';
import { Form, Input,message,Button,Select,DatePicker,InputNumber,Modal,Spin,Result } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import {
  Card,
  Col,
  Row,
} from 'reactstrap';
import * as serverconfig from '../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var sacco= ''
var username=''
var token= ''


class BankMoneyForm extends React.Component {

  state = {
    staffs:[],
    expensecategories:[],
    date:moment().format(dateFormat).toString(),  
    quantity:0,
    rate:0,
    amounttobepaid:0,
    balance:0,
    description:'',
    expensecategory:'',
    expenseto:'',
    expensetocontact:'',
    debitorphonetwo:'',
    lastexpenseno:'',
    expenseno:'',
    expensetotype:'',
    members:[],
    selectedaccount:'',
    datasubmittedsuccessfully:false,


    ////////////////////////////////
    //payments form variables
    amountpaid:0,
    paymentmode:'',
    amount:'',
    comments:'',
    phonenumberfrom:'',
    phonenumberto:'',
    accountto:'',
    clientbank:'',
    transreference:'',
    chequeno:'',
    datarequested:true,
  }
  

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})

}


  //submit button pressed
  handleFormSubmit=(event) =>{
    const accountid=this.props.accountid;

    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('accountid', accountid);
    form_data.append('moneyaction', this.state.moneyaction);
    form_data.append('amountpaid', this.state.amountpaid);

    axios.post(serverconfig.backendserverurl+'/customqueries/addbankmoney', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {
    this.setState({datarequested:false})
    this.setState({datasubmittedsuccessfully:true})
  }        

  )
  .catch(error => console.log(error))

  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully "
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{


        return (
          <div>
            
              <Form
                  name="paymentform"
                  onFinish={(event) => this.handleFormSubmit(event)}
              >
    
               <FormItem 
                      label="Account Action"
                      name="moneyaction"
                      rules={[
                        {
                          required: true,
                          message: 'Please select action',
                        },
                      ]}
                      >
                          <Select 
                          value={this.state.moneyaction} 
                          onChange={(val)=>{this.setState({moneyaction:val})}}
                          placeholder="Select Action." >
                                <Option value='addmoney'>Add Money</Option>
                                <Option value='removemoney'>Remove Money</Option>  
                          </Select>
                </FormItem>
    
                <FormItem label="Amount"
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input amount',
                    },
                  ]}
                  
                  >
                    <InputNumber
                      style={{ width: 150 }} 
                      defaultValue={0.0}
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\x\s?|(,*)/g, '')}
                      onChange={(val)=>{this.setState({amountpaid:val})}}
                    />    
                                
                  </FormItem>

                <FormItem>
                <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                </Form>
                
          </div>
        );

      }

    }

  }

}


export default BankMoneyForm;

