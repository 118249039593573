import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../serverconn'
import CurrencyFormat from 'react-currency-format';
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var sacco= ''
var bizuserid= ''
var token= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){

      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }


    calculateTotalDeposits=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totaldeposits)
      })
      return tot;
     }
  
     calculateTotalWithdraw=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totalwithdraws)
      })
      return tot;
     }
  
     calculateTotalmembership=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totalincomes_membership)
      })
      return tot;
     }
  
     calculateTotalinterest=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totalincomes_loaniterest)
      })
      return tot;
     }
  
     calculateTotalpassbook=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totalincomes_passbook)
      })
      return tot;
     }
  
     calculateTotalwithdrawcharge=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totalincomes_withdrawcharge)
      })
      return tot;
     }
  
     calculateTotalprinciplerepayments=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.total_principlerepayments)
      })
      return tot;
     }
  
     calculateTotalinterestrepayments=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.total_interestrepayments)
      })
      return tot;
     }
  
     calculateTotalsharespurchase=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.sharespurchase_total)
      })
      return tot;
     }
  
     calculateTotalsharetransfers=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.sharessold_total)
      })
      return tot;
     }
  
     calculateTotalloanissuances=()=>{
      var tot=0
      this.props.sheetitems.map((item)=>{
        tot+=Number(item.totalloanissuances)
      })
      return tot;
     }

  
    render() {
      return (
        <div style={{padding:20}}>
           <style>
            {`@media print {
            @page { size: landscape; }
             }`}
          </style>
          <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                  <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
                
                  </Col>

       </Row>

             <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>PERIODIC MEMBERS SUMMARY REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

             <reactstrp.Table bordered >
                      <thead>
                        <tr>
                          <th>Account Name</th>
                          <th>Account No</th>

                          <th>Total deposits</th>
                          <th>Total Withdraws</th>

                          <th>Total membership</th>
                          <th>Loan interest</th>
                          <th>Passbook</th>
                          <th>Withdraw charge</th>

                          <th>Total loan</th>
                          <th>Loan principle repayments</th>
                          <th>Loan interest repayments</th>

                          <th>Share purchase</th>
                          <th>Share transfer</th>


                        </tr>
                      </thead>
                      <tbody>
                      {this.props.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.FullName}</td>
                          <td>{item.AccountNumber}</td>
                          <td>{<CurrencyFormat value={item.totaldeposits} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalwithdraws} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.totalincomes_membership} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalincomes_loaniterest} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalincomes_passbook} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalincomes_withdrawcharge} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          <td>{<CurrencyFormat value={item.totalloanissuances} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td>{<CurrencyFormat value={item.total_principlerepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.total_interestrepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.sharespurchase_total} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.sharessold_total} displayType={'text'} thousandSeparator={true}/>}</td>
                        

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDeposits()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalWithdraw()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalmembership()} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalinterest()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalpassbook()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalwithdrawcharge()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalloanissuances()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalprinciplerepayments()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalinterestrepayments()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsharespurchase()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsharetransfers()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 
        </div>
      );
    }
  }


class PeriodicMemberSummaryReport extends React.Component {

  state = {
    users:[],
    sheetitems:[],
    dateone:'',
    datetwo:'',
    entrant:'',
    entrantname:'',
    totaldebit:0,
    totalcredit:0,
    datarequested:false,
   };

   componentDidMount(){
    if(localStorage.getItem("sacco")){
      sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   
   }else{
      sacco= ''
      bizuserid= ''
   }
   
   if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/accounts/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          users:res.data
        })
      })
  }

 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

   handleentrantChange= (e) => this.setState({ entrant: e});

 
   //search sales method
   searchSales=()=>{

    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('sacco', sacco);
    
    if(this.state.dateone===''||this.state.datetwo===''){
      message.error("Dates missing")
    }else{
        this.setState({sheetitems:[]})
        this.setState({datarequested:true})

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getperiodicmembersalaryreport', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
          console.log(res.data.report)
          this.setState({datarequested:false})

          this.setState({sheetitems:JSON.parse(res.data.report)})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

   calculateTotalDeposits=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totaldeposits)
    })
    return tot;
   }

   calculateTotalWithdraw=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totalwithdraws)
    })
    return tot;
   }

   calculateTotalmembership=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totalincomes_membership)
    })
    return tot;
   }

   calculateTotalinterest=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totalincomes_loaniterest)
    })
    return tot;
   }

   calculateTotalpassbook=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totalincomes_passbook)
    })
    return tot;
   }

   calculateTotalwithdrawcharge=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totalincomes_withdrawcharge)
    })
    return tot;
   }

   calculateTotalprinciplerepayments=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.total_principlerepayments)
    })
    return tot;
   }

   calculateTotalinterestrepayments=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.total_interestrepayments)
    })
    return tot;
   }

   calculateTotalsharespurchase=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.sharespurchase_total)
    })
    return tot;
   }

   calculateTotalsharetransfers=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.sharessold_total)
    })
    return tot;
   }

   calculateTotalloanissuances=()=>{
    var tot=0
    this.state.sheetitems.map((item)=>{
      tot+=Number(item.totalloanissuances)
    })
    return tot;
   }

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
     
       if(this.state.datarequested===true){
        return(
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
          <Spin indicator={antIcon} />
          </div>
        )
  
      }else{

        return(
          <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              totaldebit={this.state.totaldebit} 
              totalcredit={this.state.totalcredit} 
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              sheetitems={this.state.sheetitems}
              entrantname={this.state.entrantname}
              ref={el => (this.componentRef = el)} /> 
            </div>
  
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
              <Panel header="PERIODIC MEMBERS SUMMARY REPORT" key="1">
              <Form  layout="inline" >
 
              <FormItem label="Date Range">
                  <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
              </FormItem>

              <FormItem>
              <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
              </FormItem> 
  
             </Form>
  
             <br></br>
            <div style={{overflow:'scroll'}}>
            <reactstrp.Table bordered >
                      <thead>
                        <tr>
                          <th>Account Name</th>
                          <th>Account No</th>

                          <th>Total deposits</th>
                          <th>Total Withdraws</th>

                          <th>Total membership</th>
                          <th>Loan interest</th>
                          <th>Passbook</th>
                          <th>Withdraw charge</th>

                          <th>Total loan</th>
                          <th>Loan principle repayments</th>
                          <th>Loan interest repayments</th>

                          <th>Share purchase</th>
                          <th>Share transfer</th>


                        </tr>
                      </thead>
                      <tbody>
                      {this.state.sheetitems.map(
                        (item)=>(
                          <tr>
                          <td>{item.FullName}</td>
                          <td>{item.AccountNumber}</td>
                          <td>{<CurrencyFormat value={item.totaldeposits} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalwithdraws} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.totalincomes_membership} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalincomes_loaniterest} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalincomes_passbook} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.totalincomes_withdrawcharge} displayType={'text'} thousandSeparator={true}/>}</td>
                         
                          <td>{<CurrencyFormat value={item.totalloanissuances} displayType={'text'} thousandSeparator={true}/>}</td>

                          <td>{<CurrencyFormat value={item.total_principlerepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.total_interestrepayments} displayType={'text'} thousandSeparator={true}/>}</td>
                          
                          <td>{<CurrencyFormat value={item.sharespurchase_total} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.sharessold_total} displayType={'text'} thousandSeparator={true}/>}</td>
                        

                          </tr>
                        ))}
                        <tr>
                        <td style={{fontWeight:'bolder'}}>Total</td>
                        <td></td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalDeposits()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalWithdraw()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalmembership()} displayType={'text'} thousandSeparator={true}/>}</td>

                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalinterest()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalpassbook()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalwithdrawcharge()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalloanissuances()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalprinciplerepayments()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalinterestrepayments()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsharespurchase()} displayType={'text'} thousandSeparator={true}/>}</td>
                        <td style={{fontWeight:'bolder'}}>{<CurrencyFormat value={this.calculateTotalsharetransfers()} displayType={'text'} thousandSeparator={true}/>}</td>

                        </tr>

                    </tbody>
                </reactstrp.Table> 
            </div>


                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
               </div>

              </Panel>
              </Collapse>
          </div>
  
      )        

      }


   }
}

export default PeriodicMemberSummaryReport; 
